import React from 'react'
import { Container, Section } from 'Common'
import { Wrapper } from './styles'
export const Skills = () => (
    <Section id="skills">
        <Container>
            <Wrapper>
                <h3>Skills</h3>

                <div className="row">
                    <div className="col">
                        <h2>Backend</h2>
                        <ul>
                            <li>PHP ^7.0</li>
                            <li>Symfony 2 / 3 / 4</li>
                            <li>MySql / MariaDB</li>
                            <li>Node.js / Express.js / Sails.js</li>
                            <li>Python 3 / Django</li>
                            <li>C# / APS.NET MVC / ASP.NET CORE</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h2>Frontend</h2>
                        <ul>
                            <li>Javascript (ES5,ES6)</li>
                            <li>HTML5</li>
                            <li>CSS3 / SASS</li>
                            <li>Angular ^2 / Vue.js </li>
                            <li>Gatsby / React</li>
                        </ul>
                    </div>
                    <div className="col">
                        <h2>Miscellaneous Skills</h2>
                        <ul>
                            <li>MQTT</li>
                            <li>Firebase</li>
                            <li>GIT</li>
                            <li>Docker</li>
                            <li>Linux / Ubuntu / CentOS</li>
                            <li>IoT / Arduino / ESP8266</li>
                        </ul>
                    </div>
                </div>



            </Wrapper>
        </Container>
    </Section>
)