import React from 'react'
import { Container, Section, Button } from 'Common'
import {  Details } from './styles'
import { Link} from 'react-scroll'
export const Intro = () => (
    <Section id="about">
        <Container>
            <Details>
                <h1>Web App Developer</h1>
                <h3>Hi, I'm Błażej Korzeniewski</h3>
                <p>I'm a full stack developer with knowledge in a lot of different technologies. If I don't know something I always try to wrap my head around it quickly.</p>
                <Button as={Link} to="projects"  smooth={true} offset={-70} style={{marginTop:"10px"}}>Show my work</Button>
            </Details>
        </Container>
    </Section>
)