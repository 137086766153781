import styled from 'styled-components'

export const Details = styled.div`
    text-align:center;
        margin-top: -73px;
        h1 {
            color: white;
            background: url('../images/title-bg.png') repeat-y;
            background-size: contain;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-attachment: fixed;
            font-size: 4rem;
            font-weight: bold;
            margin: 2rem 0;
            text-align:center;
        }

        h3{
            font-size: 2rem;
            font-weight: bold;
            margin: 2rem 0;
        }

        p{
            color: ${({theme})=>theme.colors.gray};
            font-size: 1rem;
            font-weight: 400;
            line-height:1.5;
        }


        ${({ theme }) => theme.media.desktop} {
                h1 {
                font-size: 7rem;
                margin: 5rem 0;
            }

            h3{
                font-size: 4rem;
                margin: 5rem 0;
            }

            p{
                font-size: 1.6rem;
            }
        }

     
`