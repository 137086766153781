import React from 'react'
import { Wrapper, ImgWrapper, Details } from './styles'
import Img from "gatsby-image"


export class Card extends React.Component {
   
   duration = 1;
   constructor(props) {
      super(props);

      this.imgRef = React.createRef();
      this.imgWrapperRef = React.createRef();
      this.state = { translateY: 0, transitionDuration: 2 };
      this.translateY = 0;
   }



   mouseEnterHandler = () => {
      this.setState({ translateY: this.translateY })
   }

   mouseLeaveHandler = () =>{
      this.setState({ translateY:0})

   }

   clickHandler = () =>{

      window.open(this.props.url,'_blank');
   }

   loadHandler = () => {
      const imgHeight = this.imgRef.current.imageRef.current.height;
      const imgWrapperHeight  = this.imgWrapperRef.current.offsetHeight;
      this.translateY = imgHeight > imgWrapperHeight ? -(imgHeight - imgWrapperHeight) : 0;
      let transitionDuration = Math.abs((this.translateY * this.duration) / 100);
      transitionDuration = transitionDuration > this.duration ? transitionDuration : this.duration;
      this.setState({ translateY: 0, transitionDuration: transitionDuration })
   }


   render() {
         const {title,  img, description, url} = this.props;
      return (
         <Wrapper>
            <ImgWrapper ref={this.imgWrapperRef}  
               onMouseEnter={this.mouseEnterHandler}
               onMouseLeave={this.mouseLeaveHandler} 
               onDoubleClick={this.clickHandler}
               >
               <Img
               alt={title} 
               fluid={img} 
               ref={this.imgRef} 
               onLoad={this.loadHandler} 
               style={{ transform: `translateY(${this.state.translateY}px)`, transitionDuration: `${this.state.transitionDuration}s` }} />
            </ImgWrapper>
            <Details className="card-details">
               <h2><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></h2>
               <p>{description}</p>
            </Details>
         </Wrapper>
      )
   }
}