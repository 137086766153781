import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1020;
    border-bottom: 1px solid ${({theme})=>theme.colors.gray_light};
    background: ${({theme})=>theme.colors.light};
`

export const Overlay = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.7);
	width: 100%;
	height: 100%;
	display: none;
	transition: 0.4s;
`