import styled from 'styled-components'

export const ImgWrapper = styled.div`
    overflow: hidden;
   
    border-radius: 5px;
    box-shadow: 0px 20px 20px 0px #0000002e;
        max-height: 250px;
         min-width: 220px;


     &>div{
      width:100%;
      transform: translateY(0);
      transition: transform 2s;
      transition-delay: .5s
    }
    ${({theme}) => theme.media.phone} {
        max-height: 250px;
         min-width: 250px;
    }
    ${({theme}) => theme.media.tablet} {
        max-height: 300px;
        min-width: 300px;
    }
    
    ${({theme}) => theme.media.desktop} {
        max-height: 300px;
         min-width: 300px;
    }
`;


export const Details = styled.div`
        opacity: 0;
        transform: translateY(50px);
        transition-duration: .2s;
        transition-property: opacity, transform;
        transition-delay: .1s;
        margin-top:20px;
      
    
`
export const Wrapper = styled.figure`
    margin: 0 20px;
    transform: scale(1);
    transition: transform 0.3s;
    padding: 30px 0;
 

   


   
    a{
        ::after{
            content: "";
        background-image: url(../images/link-icon.png);
        width: 34px;
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        display: inline-block;
        float: right;
        opacity: .5;
        transform: scale(1);
        transition-property: opacity, transform;
        transition-duration: .2s;
        }

        :hover::after{
            transform: scale(1.2);
            opacity: 1;
        }

    }

    &:hover{
        transform: scale(1.1);
    }

    &:hover  .card-details{
        opacity: 1;
        transform: translateY(0);
    }
    
`