import styled from 'styled-components'

export const Wrapper = styled.nav`
 & a, & button{
        margin: 0 20px;
		font-weight: bold;
		cursor: pointer;
	}
	
	& .active{
		border-bottom: 3px solid black;
	}

    ${({ desktop }) =>
		desktop
			? `
			@media (max-width: 960px) {
					display: none;
			}
			a {
					margin-right: 1rem;
					&:last-child {
							margin-right: unset;
					}
			}
		`
			: `
			padding: 3rem;
			display: flex;
			flex-direction: column;
			a {
					margin-bottom: 1rem;
					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`