import React from 'react'
import { Section, Container } from 'Common'
import { Card } from './Card'
import { Wrapper } from './styles'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useStaticQuery, graphql } from "gatsby"


const SliderSettings = {
  dots: false,
  speed: 500,
  centerPadding: "60px",
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  infinite: true,
  swipeToSlide: true,
  focusOnSelect: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        centerPadding: "50px",
      }
    },

  ]
};

export const Projects = () => {

  const data = useStaticQuery(graphql`
  query  {
      allProjectsJson {
        edges {
          node {
            title
            url
            slug
            description
            image {
              childImageSharp {
                sizes(maxWidth: 300 ) {
                  ...GatsbyImageSharpSizes_tracedSVG
                }
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }      
`)

  return (


    <Section id="projects">
      <Wrapper>
        <Container>
          <h1>Projects</h1>
          <p>Hover over the cards to learn more about projects</p>
        </Container>
          <Slider {...SliderSettings}>
            {data.allProjectsJson.edges.map(({node}, i) => (
              <Card key={i} description={node.description} slug={node.slug} img={node.image.childImageSharp.fluid} title={node.title} url={node.url}/>
            ))}
          </Slider>
          <Container style={{text:"center"}}>
          </Container>
      </Wrapper>
    </Section>
  );
}
