import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    ${({theme}) => theme.media.phone} {
        max-width: 720px;
    }
    ${({theme}) => theme.media.tablet} {
        max-width: 960px;
    }
    
    ${({theme}) => theme.media.desktop} {
        max-width: 1140px;
    }
`;
