import React from 'react'
import { Container, Section, Button } from 'Common'
import { Details } from './styles'

export const  Contact = () => (
    <Section id="contact">
        <Container>
            <Details>
                <h3>Contact</h3>
                <p>Just send me an email</p>
              <form action="#" method="post">
                  <div className="form-inline">
                    <div className="form-group">
                        <input type="text" name="name" placeholder="Name" required></input>
                    </div>
                    <div className="form-group">
                        <input type="text" name="surname" placeholder="Surname" required></input>
                    </div>
                    <div className="form-group">
                        <input type="mail" name="email" placeholder="Email" required></input>
                    </div>
                  </div>
                  <div className="form-group">
                      <textarea name="message" rows="5" placeholder="Message" required>
                      </textarea>
                  </div>
                  <Button>Send</Button>
              </form>
            </Details>
        </Container>
    </Section>
)

