import styled from "styled-components"

export const Button = styled.button`
    display: inline-block;
    font-weight: 400;
    color:  #ffffff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 1.2;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: rgb(51, 64, 255);
    box-shadow: 0px 1px 3px 0px #00000085;
    border-color: #007bff;
    cursor: pointer;
`;
