import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 1.5rem 0;
	display: flex;
	align-items: center;
    justify-content: space-between;
    & > a{
        font-size: 1.25rem;
        font-weight: bold;
    }

    
`