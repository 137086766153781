import styled from 'styled-components'

export const Wrapper = styled.div`

    h3{
        font-size: 4rem;
            font-weight: bold;
            margin: 3rem 0;
    }


    .row{

        display: block;
            width:100%;

        .col{
            width: 100%
        }
    }

    ${({ theme }) => theme.media.tablet} {
        .row{
            display: flex   ;
            }
        }
`