import React from "react";
import  {Layout} from "Common";

import SEO from "Common/SEO" 
import {Intro, Projects, Contact, Skills} from "Components/landing"

export default () => <Layout>
        <SEO title="Błażej Korzeniewski" />
        <Intro/>
        <Projects/>
        <Skills/>
        <Contact/>
</Layout>