import styled from 'styled-components'

export const Details = styled.div`
    text-align:center;
        padding-bottom: 50px;
        h3{
            font-size: 4rem;
            font-weight: bold;
            margin: 3rem 0;
        }

        p{
            color: ${({ theme }) => theme.colors.gray};
            font-size: 1.6rem;
            font-weight: 400;
        }

        .form-inline{
            display: block;
            width:100%;

        }


        ${({ theme }) => theme.media.tablet} {
            .form-inline{
            display: flex   ;
            }
        }

        .form-group{
                width:100%;
                padding: 20px;   
            }

        input, textarea{
                background-color: hsla(0,0%,0%,0.04);
                color: ${({ theme }) => theme.colors.white};
                width: 100%;
                border: none;
                padding: 20px;
                border-radius: 5px;

               
        }
        ::placeholder{
                    color: ${({ theme }) => theme.colors.white};
                    opacity: 1;
                }
`