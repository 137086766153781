import React from 'react'
import { Container } from 'Common'
import { Wrapper } from './styles'

export const Footer = () => (
        <Wrapper>
            <Container>
            Made by Błażej Korzeniewski
            </Container>
        </Wrapper>
)