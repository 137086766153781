import React from 'react'
import { Wrapper } from './styles'
import { Link} from 'react-scroll'

const NavbarLinks = ({ desktop }) => (
	<Wrapper desktop={desktop}>
		<Link offset={-73}  spy={true} smooth={true} to="about">About</Link>
		<Link offset={-70}  spy={true} smooth={true} to="projects">Projects</Link>
		<Link offset={-70}  spy={true} smooth={true} to="skills">Skills</Link>
		<Link offset={-73} spy={true} smooth={true} to="contact">Contact</Link>
	</Wrapper>
)

export default NavbarLinks