import React from 'react'
import { Global } from './styles'
import { ThemeProvider } from "styled-components"
import { theme } from '../Utils/theme'
import './fonts.css'
import { Header, Footer } from 'Theme'

export const Layout = ({ children }) => (
	<ThemeProvider theme={theme}>
		<>
			<Global/>
			<Header/>
			{children}
			<Footer/>
		</>
	</ThemeProvider>
)